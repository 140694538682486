$breakpoint: (
'mobile': 'screen and (max-width: 1000px)',
'desktop': 'screen and (max-width: 1200px)',
) !default;

@mixin breakpoint($window-size: desktop) {
@media #{map-get($breakpoint, $window-size)} {
@content;
}
}

@mixin font-face($family, $path) {
@font-face {
font-family: $family;
src: url('#{$path}.woff') format('woff'),
url('#{$path}.ttf') format('truetype');
}
}

@mixin font-size($font-size, $line-height: 28) {
$rem: $font-size / 14;
$line-height-size: $line-height / $font-size;
font-size: #{$font-size}px;
font-size: #{$rem}rem;
line-height: $line-height-size;
}

@mixin text-color ($color: #4b4b4b) {
color: $color;
}

@mixin link-color ($color: #e5594e) {
color: $color;
}

@mixin sub-color ($color: #969696) {
color: $color;
}

html {
font-family: 'NotoMedium';
font-size: 14px;
text-align: justify;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}