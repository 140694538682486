@import "config";

#title {
margin: 0 0 40px 0;

@include breakpoint(mobile) {
margin: 0 0 20px 0;
}

h1 {
font-family: 'Dancing Script', cursive;
@include font-size(90,90);
text-transform: capitalize;
margin: 0 0 40px 0;

@include breakpoint(mobile) {
@include font-size(50,50);
margin: 0 0 20px 0;
}
}

p {
font-family: 'Libre Baskerville', serif;
@include font-size(30,30);
letter-spacing: 0.03em;
text-transform: capitalize;
margin: 0 0 40px 0;

@include breakpoint(mobile) {
@include font-size(20,20);
margin: 0 0 20px 0;
}
}

nav {
border-top: 1px solid #4b4b4b;
border-bottom: 1px solid #4b4b4b;

ul {
display: -webkit-flex;
display: flex;
padding: 20px 10px;

@include breakpoint(mobile) {
padding: 10px 5px;
}

li {
font-family: 'Lato', sans-serif;
@include font-size(17,17);
letter-spacing: 0;
text-transform: capitalize;
margin: 0 50px 0 0;

@include breakpoint(mobile) {
@include font-size(13,13);
margin: 0 15px 0 0;
}

small {
@include font-size(13,13);
margin: 0 0.2em;
}

a {
transition: 0.3s;
}

a:hover {
@include link_color();
}
}


li:last-child {
padding: 0 20px 0 0;
margin: 0 0 0 0;
background-image: url(../../images/icon_link.svg);
background-size: 15px 11px;
background-position: right center;
background-repeat: no-repeat;
}
}
}
}//title


#detail {
letter-spacing: 0.03em;

h2 {
font-family: 'GeneiKoburiMincho';
@include font-size(28,28);
letter-spacing: -0.03em;
margin: 0 0 20px 0;

@include breakpoint(mobile) {
@include font-size(20,20);
margin: 0 0 10px 0;
}

.lettering_1 {
margin: 0 -0.04em 0 -0.06em;
}

.lettering_2 {
margin: 0 -0.06em 0 -0.1em;
}

.lettering_3 {
margin: 0 -0.05em 0 -0.02em;
}

.lettering_4 {
margin: 0 -0.2em 0 -0.1em;
}

.lettering_5 {
margin: 0 -0.14em 0 -0.14em;
}

.lettering_6 {
margin: 0 0 0 -0.14em;
}

.lettering_7 {
margin: 0 0 0 -0.05em;
}

.lettering_8 {
margin: 0 -0.06em 0 -0.06em;
}

.lettering_9 {
margin: 0 -0.06em 0 -0.1em;
}

.lettering_3 {
margin: 0 -0.06em 0 -0.02em;
}

.lettering_4 {
margin: 0 -0.2em 0 -0.1em;
}

.lettering_5 {
margin: 0 -0.18em 0 -0.14em;
}

.lettering_6 {
margin: 0 0 0 -0.14em;
}

.lettering_7 {
margin: 0 0 0 -0.04em;
}

.lettering_8 {
margin: 0 -0.02em 0 -0.08em;
}

.lettering_9 {
margin: 0 -0.12em 0 -0.1em;
}

.lettering_10 {
margin: 0 -0.14em 0 -0.08em;
}

.lettering_11 {
margin: 0 0 0 -0.08em;
}
}

#greeting {
margin: 0 0 20px 0;
}

#slider {
margin: 0 0 60px 0;

@include breakpoint(mobile) {
margin: 0 0 30px 0;
}
}

#outline {
section {
margin: 0 0 20px 0;
display: -webkit-flex;
display: flex;
-webkit-justify-content: space-between;
justify-content: space-between;
-webkit-align-items: center;
align-items: center;

@include breakpoint(mobile) {
margin: 0 0 10px 0;
}

p:first-child {
width: 30%;

@include breakpoint(mobile) {
display: none;
}
}

p:last-child {
width: 67%;

@include breakpoint(mobile) {
width: 100%;
}
}
}

section:last-child {
margin: 0 0 40px 0;
-webkit-flex-direction: row-reverse;
flex-direction: row-reverse;

@include breakpoint(mobile) {
margin: 0 0 50px 0;
}
}
}

#repair {
margin: 0 0 60px 0;

@include breakpoint(mobile) {
margin: 0 0 50px 0;
}
}

#place {
margin: 0 0 100px 0;
display: -webkit-flex;
display: flex;
-webkit-align-items: flex-end;
align-items: flex-end;

@include breakpoint(mobile) {
display: block;
margin: 0 0 50px 0;
}

p img {
width: 300px;

@include breakpoint(mobile) {
width: 100%;
margin: 0 0 10px 0;
}
}

address {
@include font-size(12,18); 
font-style: normal;
padding: 0 0 0 10px;

@include breakpoint(mobile) {
padding: 0 0 0 0;
}
}
}
}//detail